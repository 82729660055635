import React from 'react';
import {useTranslation} from "react-i18next";
import style from "./HeroSectionComponent.module.css"

const HeroSectionComponent = () => {
    const {t} = useTranslation();

    return (
        <section className={style.heroSection}>

            <div className={style.memoriesWrapper}>
                <p>{t('share_your_memories')}</p>
            </div>
            <div className={style.h1Wrapper}>
                <p className={style.recipient}>
                    {t('recipient')}
                </p>
                <h1>
                    <span className={style.mrLeft}>{t('address')} </span>
                    <span>{t('for_free_radio')}</span>
                </h1>
            </div>
            <div className={style.imgWrapper}>
                <img src={`${process.env.PUBLIC_URL}/images/image.webp`} alt={'Зруйнована будівля'}/>
            </div>

        </section>
    );
};

export default HeroSectionComponent;

