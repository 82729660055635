import React from 'react';
import HeroSectionComponent from "../HeroSectionComponent/HeroSectionComponent";
import AboutSectionComponent from "../AboutSectionComponent/AboutSectionComponent";
import UkrMapSectionComponent from "../UkrMapSectionComponent/UkrMapSectionComponent";
import DonatesComponent from "../DonatesComponent/DonatesComponent";
import FooterComponent from "../FooterComponent/FooterComponent";
import ShowLettersComponent from "../ShowLettersComponent/ShowLettersComponent";

const HomePageComponent = () => {

    return (
        <div>
            <HeroSectionComponent />
            <AboutSectionComponent />
            <UkrMapSectionComponent />
            <ShowLettersComponent/>
            <DonatesComponent/>
            <FooterComponent/>
        </div>
    );
};

export default HomePageComponent;

