import React, { ReactNode, FC, useEffect, useRef, useState } from 'react';
import style from './ModalComponent.module.css';
import LikeButton from "../LikeButtonComponent/LikeButtonComponent";
import { useTranslation } from "react-i18next";

interface ModalProps {
    letterId?: string;
    initialLikes?: number;
    onClose: () => void;
    children: ReactNode;
    isLetter: boolean;
}

const ModalComponent: FC<ModalProps> = ({ children, onClose, initialLikes, letterId, isLetter }) => {
    const { t } = useTranslation();
    const [isSticky, setIsSticky] = useState(false);
    const modalContentRef = useRef<HTMLDivElement>(null);

    const handleScroll = () => {
        const scrollTop = modalContentRef.current?.scrollTop;
        setIsSticky(!!scrollTop && scrollTop > 50);
    };

    useEffect(() => {
        document.body.classList.add('noScroll');

        const modalContent = modalContentRef.current;
        modalContent?.addEventListener('scroll', handleScroll);

        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.body.classList.remove('noScroll');
            modalContent?.removeEventListener('scroll', handleScroll);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [onClose]);

    // Прокручуємо вміст модального вікна до початку при кожній зміні контенту або при відкритті модального вікна
    useEffect(() => {
        const modalContent = modalContentRef.current;
        if (modalContent) {
            setTimeout(() => {
                modalContent.scrollTo(0, 0); // Прокручуємо до верху після затримки
            }, 50);
        }
    }, [children, isLetter]);

    return (
        <div className={style.modalOverlay} onClick={onClose}>
            <div
                className={style.modalContent}
                ref={modalContentRef}
                onClick={(e) => e.stopPropagation()} // Запобігаємо закриттю при кліку на контент
            >
                {isLetter ? (
                    <div className={`${style.stickyContainer} ${isSticky ? style.sticky : ''}`}>
                        {letterId && initialLikes !== undefined && (
                            <LikeButton
                                singleLetterId={letterId}
                                initialLikes={initialLikes}
                                customClass={style.customLikeButton}
                            />
                        )}
                        <button
                            className={style.closeModal}
                            onClick={onClose}
                        >
                            {t("close")}
                        </button>
                    </div>
                ) : (
                    <button className={style.closeModalForm} onClick={onClose}>
                        {t("close")}
                    </button>
                )}
                {children}
            </div>
        </div>
    );
};

export default ModalComponent;
