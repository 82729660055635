import {createBrowserRouter, RouteObject} from "react-router-dom";
import MainLayout from "../layouts/MainLayout/MainLayout";
import LettersPage from "../pages/LettersPage/LettersPage";
import HomePage from "../pages/HomePage/HomePage";

const routes: RouteObject[] = [
    {
        path: '/',
        element: <MainLayout />,
        children: [
            { index: true, element: <HomePage /> },
            { path: 'letters', element: <LettersPage /> },
            { path: 'letters/:letterId', element: <LettersPage /> },
        ],
    },
];
const router = createBrowserRouter(routes);

export {
    router
}