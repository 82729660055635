import React, {useState, useEffect} from 'react';
import {collection, getDocs} from 'firebase/firestore';
import {db} from '../../../firebase/firebaseConfig';
import DemoLetterCard from '../../../components/DemoLetterCard/DemoLetterCard';
import {ILetterModel} from '../../../models/ILetterModel';
import {useTranslation} from 'react-i18next';
import ModalComponent from '../../ModalComponent/ModalComponent';
import LetterComponent from '../../LetterComponent/LetterComponent';
import style from './ShowLettersComponent.module.css';
import {NavLink} from 'react-router-dom';

interface ShowLettersComponentProps {
}

const ShowLettersComponent: React.FC<ShowLettersComponentProps> = () => {
    const {t} = useTranslation();
    const [letters, setLetters] = useState<ILetterModel[]>([]);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedLetterId, setSelectedLetterId] = useState<string | null>(null);
    const [initialLikes, setInitialLikes] = useState<number | undefined>(undefined);
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
    const [touchStartX, setTouchStartX] = useState<number | null>(null);
    const [touchEndX, setTouchEndX] = useState<number | null>(null);
    const [mouseDownX, setMouseDownX] = useState<number | null>(null);
    const [mouseUpX, setMouseUpX] = useState<number | null>(null);

    useEffect(() => {
        const fetchLetters = async () => {
            setLoading(true);
            try {
                const lettersSnapshot = await getDocs(collection(db, 'letters'));
                let lettersList: ILetterModel[] = lettersSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                } as ILetterModel));

                lettersList = lettersList
                    .sort((a, b) => (b.likes || 0) - (a.likes || 0))
                    .slice(0, 100);

                setLetters(lettersList);
            } catch (error) {
                console.error('Помилка при завантаженні листів:', error);
                setError('Не вдалося завантажити листи');
            } finally {
                setLoading(false);
            }
        };

        fetchLetters();
    }, []);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);// Перевірка
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const showPrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : letters.length - 1
        );
    };

    const handleTouchStart = (e: React.TouchEvent) => {
        setTouchStartX(e.touches[0].clientX);
    };
    const handleTouchMove = (e: React.TouchEvent) => {
        setTouchEndX(e.touches[0].clientX);
    };
    const handleMouseDown = (e: React.MouseEvent) => {
        setMouseDownX(e.clientX);
    };
    const handleMouseMove = (e: React.MouseEvent) => {
        setMouseUpX(e.clientX);
    };
    const handleTouchEnd = () => {
        if (touchStartX !== null && touchEndX !== null) {
            const distance = touchStartX - touchEndX;

            if (distance > 50) {
                showNext();
            } else if (distance < -50) {
                showPrev();
            }
        }
        setTouchStartX(null);
        setTouchEndX(null);
    };
    const handleMouseUp = () => {
        if (mouseDownX !== null && mouseUpX !== null) {
            const distance = mouseDownX - mouseUpX;

            if (distance > 50) {
                showNext();
            } else if (distance < -50) {
                showPrev();
            }
        }
        setMouseDownX(null);
        setMouseUpX(null);
    };



    const showNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex < letters.length - 1 ? prevIndex + 1 : 0
        );
    };

    if (loading) {
        return <p>Завантаження...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    if (letters.length === 0) {
        return <p>Немає листів для відображення</p>;
    }

    if (isMobile) {
        return (
            <section className={style.showLettersComponent}>
                <h2 className={style.h2send}>{t('sent')}</h2>
                <div className={style.mobileList}>
                    {letters.slice(0, 3).map((letter) => (
                        <div key={letter.id} className={style.mobileItem}>
                            <DemoLetterCard
                                letter={letter}
                                onClick={() => {
                                    setSelectedLetterId(letter.id);
                                    setInitialLikes(letter.likes);
                                    setModalOpen(true);
                                }}
                            />
                        </div>
                    ))}
                </div>

                {isModalOpen && selectedLetterId && (
                    <ModalComponent
                        onClose={() => setModalOpen(false)}
                        letterId={selectedLetterId}
                        initialLikes={initialLikes}
                        isLetter={true}
                    >
                        <LetterComponent letterId={selectedLetterId}/>
                    </ModalComponent>
                )}
                <div className={style.buttonPageWrapper}>
                        <NavLink className={style.buttonPage} to={'/letters'}>{t('show_more')}</NavLink>
                </div>
            </section>
        );
    }

    return (
        <section className={style.showLettersComponent}>
            <h2 className={style.h2send}>{t('sent')}</h2>
            <div className={style.carouselContainer}
                 onTouchStart={handleTouchStart}
                 onTouchMove={handleTouchMove}
                 onTouchEnd={handleTouchEnd}
                 onMouseDown={handleMouseDown}
                 onMouseMove={handleMouseMove}
                 onMouseUp={handleMouseUp} >
                <div className={style.sliderWrapper}>
                    <div className={style.prevArrow} onClick={showPrev}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="8" viewBox="0 0 32 8" fill="none">
                            <path
                                d="M31 4.5C31.2761 4.5 31.5 4.27614 31.5 4C31.5 3.72386 31.2761 3.5 31 3.5V4.5ZM0.646444 3.64645C0.451183 3.84171 0.451183 4.15829 0.646444 4.35355L3.82842 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.73079 7.34027 4.73079 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.73079 0.976311 4.73079 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82842 0.464466L0.646444 3.64645ZM31 3.5L0.999998 3.5V4.5L31 4.5V3.5Z"
                                fill="black"/>
                        </svg>
                    </div>

                    {letters.map((letter, index) => {
                        const position = (index - currentIndex + letters.length) % letters.length;
                        let itemStyle: React.CSSProperties = {};
                        let opacity = 0;
                        let zIndex = 0;
                        let scale = 0.8;
                        let left = '50%';
                        let translateX = '-50%';
                        let rotate = '0deg';

                        if (position === 0) {
                            // Center item
                            opacity = 1;
                            zIndex = 2;
                            scale = 1;
                            left = '50%';
                            translateX = '-50%';
                            rotate = '0deg';
                        } else if (position === 1) {
                            // Right item
                            opacity = 0.6;
                            zIndex = 1;
                            scale = 0.8;
                            left = '75%';
                            translateX = '-50%';
                            rotate = '4.32deg'; // Rotate to the right
                        } else if (position === letters.length - 1) {
                            // Left item
                            opacity = 0.6;
                            zIndex = 1;
                            scale = 0.8;
                            left = '25%';
                            translateX = '-50%';
                            rotate = '-4.32deg'; // Rotate to the left
                        } else {
                            // Hidden items
                            opacity = 0;
                            zIndex = 0;
                            scale = 0.8;
                            left = '50%';
                            translateX = '-50%';
                            rotate = '0deg';
                        }

                        itemStyle = {
                            left: left,
                            opacity: opacity,
                            zIndex: zIndex,
                            transform: `translateX(${translateX}) scale(${scale}) rotate(${rotate})`,
                            transition: 'all 0.5s ease-in-out',
                            cursor: position === 0 ? 'pointer' : 'default', // Show pointer cursor on center item
                        };

                        // Handle click event
                        const handleCardClick = () => {
                            if (position === 0) {
                                // Center item clicked, open modal
                                setSelectedLetterId(letter.id);
                                setInitialLikes(letter.likes);
                                setModalOpen(true);
                            }
                        };

                        return (
                            <div
                                key={letter.id}
                                className={style.sliderItem}
                                style={itemStyle}
                            >
                                <DemoLetterCard
                                    letter={letter}
                                    onClick={handleCardClick}
                                />
                            </div>
                        );
                    })}

                    <div className={style.nextArrow} onClick={showNext}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="8" viewBox="0 0 32 8" fill="none">
                            <path
                                d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5L1 3.5ZM31.3536 4.35356C31.5488 4.15829 31.5488 3.84171 31.3536 3.64645L28.1716 0.464468C27.9763 0.269206 27.6597 0.269206 27.4645 0.464468C27.2692 0.659731 27.2692 0.976313 27.4645 1.17158L30.2929 4L27.4645 6.82843C27.2692 7.02369 27.2692 7.34027 27.4645 7.53554C27.6597 7.7308 27.9763 7.7308 28.1716 7.53554L31.3536 4.35356ZM1 4.5L31 4.5L31 3.5L1 3.5L1 4.5Z"
                                fill="black"/>
                        </svg>
                    </div>
                </div>


                {isModalOpen && selectedLetterId && (
                    <ModalComponent
                        onClose={() => setModalOpen(false)}
                        letterId={selectedLetterId}
                        initialLikes={initialLikes}
                        isLetter={true}
                    >
                        <LetterComponent letterId={selectedLetterId}/>
                    </ModalComponent>
                )}
            </div>
            <div className={style.buttonPageWrapper}>
                <button className={style.buttonPage}>
                    <NavLink to={'/letters'}>{t('show_more')}</NavLink>
                </button>
            </div>
        </section>
    );
};

export default ShowLettersComponent;


