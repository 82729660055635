import React, { useState, useEffect } from 'react';
import style from './HeaderComponent.module.css';
import ModalComponent from '../ModalComponent/ModalComponent';
import FormComponent from '../FormComponent/FormComponent';
import ThankYouComponent from '../ThankYouComponent/ThankYouComponent'; // Додаємо ThankYouComponent
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import LanguageSwitcherComponent from "../LanguageSwitcherComponent/LanguageSwitcherComponent";

const HeaderComponent = () => {
    const { t } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [letterId, setLetterId] = useState<string | null>(null); // Додаємо стан для зберігання letterId

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const openFormModal = () => {
        setIsFormOpen(true);
        setIsMenuOpen(false);
    };

    const closeFormModal = () => {
        setIsFormOpen(false);
    };

    const openThankYouModal = (newLetterId: string) => {
        setLetterId(newLetterId);  // Зберігаємо letterId
        setIsFormOpen(false);      // Закриваємо модальне вікно з формою
    };

    const closeThankYouModal = () => {
        setLetterId(null);         // Закриваємо ThankYou модальне вікно
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 850);
        };
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleHashLinkClick = (targetId: string) => {
        setIsMenuOpen(false);

        setTimeout(() => {
            const targetSection = document.getElementById(targetId);
            if (targetSection) {
                const y = targetSection.getBoundingClientRect().top + window.pageYOffset - 100;
                window.scrollTo({ top: y, behavior: 'smooth' });
            }
        }, 100);
    };

    return (
        <header className={style.header}>
            <svg className={style.svg} xmlns="http://www.w3.org/2000/svg" width="100" height="44" viewBox="0 0 100 44" fill="none">
                <path d="M41.6579 0L42.5165 22H0V0H41.6579ZM61.6331 0C51.6646 0 43.4799 7.37778 42.6023 22H42.5165V44H44.6151C44.6151 37.9259 46.1986 32.4296 48.7742 28.4296C51.3307 24.4593 54.8698 22 58.7904 22H100V0H61.6427H61.6331Z" fill="#141414" />
            </svg>

            <div className={style.menu}>
                <HashLink smooth to="/#aboute-section" onClick={() => handleHashLinkClick('aboute-section')}>
                    {t('about_project')}
                </HashLink>
                <HashLink smooth to="/#donates-section" onClick={() => handleHashLinkClick('donates-section')}>
                    {t('donate')}
                </HashLink>
                <NavLink to={'/letters'}>{t('read_letters')}</NavLink>
            </div>

            <div className={style.menuLetter}>
                <LanguageSwitcherComponent />

                {isMobile ? (
                    <button className={style.menuButton} onClick={toggleMenu}></button>
                ) : (
                    <button className={style.sendLetter} onClick={openFormModal}>{t('write_letter')}</button>
                )}
            </div>

            <div className={`${style.modal} ${isMenuOpen ? style.open : ''}`}>
                <div className={style.modalContent}>
                    <div className={style.modalTop}>
                        <svg className={style.svg2} xmlns="http://www.w3.org/2000/svg" width="100" height="44" viewBox="0 0 100 44" fill="none">
                            <path d="M41.6579 0L42.5165 22H0V0H41.6579ZM61.6331 0C51.6646 0 43.4799 7.37778 42.6023 22H42.5165V44H44.6151C44.6151 37.9259 46.1986 32.4296 48.7742 28.4296C51.3307 24.4593 54.8698 22 58.7904 22H100V0H61.6427H61.6331Z" fill="#141414" />
                        </svg>
                        <button className={style.closeButton} onClick={toggleMenu}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                <path d="M1.10254 1L35.3972 35.2947" stroke="black" />
                                <path d="M1 35.4424L35.2947 1.1477" stroke="black" />
                            </svg>
                        </button>
                    </div>
                    <div className={style.modalNav}>
                        <NavLink to="/#aboute-section" onClick={() => handleHashLinkClick('aboute-section')}>
                            {t('about_project')}
                        </NavLink>
                        <HashLink smooth to="/#donates-section" onClick={() => handleHashLinkClick('donates-section')}>
                            {t('donate')}
                        </HashLink>
                        <NavLink to={'/letters'} onClick={toggleMenu}>{t('read_letters')}</NavLink>
                    </div>
                    <button className={style.sendLetterModal} onClick={openFormModal}>{t('write_letter')}</button>
                </div>
                <div className={style.overlay} onClick={toggleMenu}></div>
            </div>

            {/* Модальне вікно для форми */}
            {isFormOpen && (
                <ModalComponent onClose={closeFormModal} isLetter={false}>
                    <FormComponent closeModal={closeFormModal} openThankYouModal={openThankYouModal} />
                </ModalComponent>
            )}

            {/* Модальне вікно для подяки */}
            {letterId && (
                <ModalComponent onClose={closeThankYouModal} isLetter={false}>
                    <ThankYouComponent letterId={letterId} />
                </ModalComponent>
            )}
        </header>
    );
};

export default HeaderComponent;
