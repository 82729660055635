
export const TAGS = [
    "school_1",
    "school_2",
    "school_4",
    "school_5",
    "school_7",
    "school_9",
    "school_10",
    "school_11",
    "school_12",
    "school_18",
    "school_24",
    "sports_college",
    "medical_college",
    "technical_college",
    "institute",
    "uipa",
    "donuep",
    "toptalivka",
    "komarovka",
    "50th",
    "vodokanal",
    "meat_comb",
    "zabakhmutka",
    "plane",
    "poselok",
    "alley_of_roses",
    "ivanivske",
    "krasna_hora",
];