import React, {FC, useEffect, useState} from 'react';
import {ILetterModel} from "../../models/ILetterModel";
import style from "./LetterComponent.module.css";
import {doc, getDoc} from "firebase/firestore";
import {db} from "../../firebase/firebaseConfig";
import {useTranslation} from "react-i18next";

interface LetterComponentProps {
    letterId: string;
}

const LetterComponent: FC<LetterComponentProps> = ({letterId}) => {
    const {t} = useTranslation();
    const [letter, setLetter] = useState<ILetterModel | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchLetter = async () => {
            try {
                const docRef = doc(db, 'letters', letterId);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setLetter({id: docSnap.id, ...docSnap.data()} as ILetterModel);
                } else {
                    setError('Лист не знайдено');
                }
            } catch (err) {
                setError('Помилка при завантаженні листа');
            } finally {
                setLoading(false);
            }
        };

        fetchLetter();
    }, [letterId]);

    if (loading) {
        return <p>Завантаження...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    if (!letter) {
        return <p>Лист не знайдено.</p>;
    }

    return (
        <div className={style.letterContainer}>
            <div className={style.contentWrapper}>
                <div>
                    <h2>{letter.senderName} {letter.age && `, ${letter.age}${t('years')}`}</h2>
                    <h2>{t(`regions.${letter.senderRegion}`)}</h2>
                </div>
                <div className={style.imgWrapper}>
                    {letter.photos && letter.photos.map((photo, index) => (
                        <img key={index} src={photo} alt="LetterPhoto" style={{width: '100%', height: '100%'}}/>
                    ))}
                </div>
                <div className={style.tagsWrapper}>
                    <h4>{t('tags_name')}</h4>
                    <div className={style.tagsBlock}>
                        {letter.tags && letter.tags.map((tag, index) => (
                            <div key={index} className={style.tags}>{t(`tags.${tag}`)}</div>
                        ))}
                    </div>
                </div>
                <div className={style.paragraphWrapper}>
                    <p className={style.underlineParagraph}>{letter.letterText}</p>
                </div>
            </div>
        </div>
    );
};

export default LetterComponent;