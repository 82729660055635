import { db } from '../firebase/firebaseConfig';
import {
    collection,
    getDocs,
    query,
    limit,
    startAfter,
    getCountFromServer,
    orderBy,
    where,
    doc, getDoc
} from 'firebase/firestore';
import { ILetterModel } from '../models/ILetterModel';


export const fetchFilteredLetters = async (
    pageSize: number,
    tags: string[],
    sort: string,
    lastVisible: any = null
): Promise<{ letters: ILetterModel[], lastVisible: any }> => {
    const lettersCollection = collection(db, 'letters');


    let q = query(lettersCollection);


    if (tags.length > 0) {
        q = query(q, where('tags', 'array-contains-any', tags));
    }


    switch (sort) {
        case 'most_popular':
            q = query(q, orderBy('likes', 'desc'));
            break;
        case 'least_popular':
            q = query(q, orderBy('likes', 'asc'));
            break;
        case 'oldest':
            q = query(q, orderBy('createdAt', 'asc'));
            break;
        case 'newest':
            q = query(q, orderBy('createdAt', 'desc'));
            break;
        default:
            q = query(q, orderBy('senderName'));
    }


    if (lastVisible) {
        q = query(q, startAfter(lastVisible));
    }


    q = query(q, limit(pageSize));

    const snapshot = await getDocs(q);

    const letters = snapshot.docs.map(doc => {
        const data = doc.data();
        return {
            id: doc.id,
            ...data
        } as ILetterModel;
    });

    const newLastVisible = snapshot.docs[snapshot.docs.length - 1];

    return { letters, lastVisible: newLastVisible };
};
export const fetchLettersCountByRegion = async (region: string): Promise<number> => {
    const lettersCollection = collection(db, 'letters');
    const q = query(lettersCollection, where('senderRegion', '==', region));


    const snapshot = await getCountFromServer(q);
    return snapshot.data().count; // Повертаємо кількість листів
};
export const fetchTotalAmount = async (): Promise<number | null> => {
    const docRef = doc(db, 'donations', 'totalAmount');
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return docSnap.data().sum as number;
    } else {
        console.log('Документ не знайдено');
        return null;
    }
};
