
export const REGIONS = [
    "vinnytska",
    "volynska",
    "dnipropetrovska",
    "donetska",
    "zhytomyrska",
    "zakarpatska",
    "zaporizka",
    "ivano_frankivska",
    "kyivska",
    "kirovohradska",
    "luhanska",
    "lvivska",
    "mykolaivska",
    "odeska",
    "poltavska",
    "rivnenska",
    "sumska",
    "ternopilska",
    "kharkivska",
    "khersonska",
    "khmelnytska",
    "cherkaska",
    "chernivetska",
    "chernihivska",
    "avtonomna_respublika_krym",
    "abroad"
];