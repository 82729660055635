import React from 'react';
import HomePageComponent from "../../components/HomePageContainer/HomePageComponent/HomePageComponent";
import style from './HomePage.module.css'

const HomePage = () => {
    return (
        <div className={style.homePage}>
            <HomePageComponent/>
        </div>
    );
};

export default HomePage;

