import React, { FC, useState, useRef, useEffect } from 'react';
import { db, storage } from '../../firebase/firebaseConfig';
import { ILetterModel } from "../../models/ILetterModel";
import { collection, addDoc, DocumentReference, serverTimestamp } from "firebase/firestore";
import { REGIONS } from "../../constants/regions";
import { TAGS } from "../../constants/tags";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { letterValidationSchema } from '../../validators/sendLetterFormValidator';
import { containsLink, filterText } from "../../services/textFilterService";
import style from './FormComponent.module.css';
import { useTranslation } from "react-i18next";

interface FormComponentProps {
    closeModal: () => void;
    openThankYouModal: (letterId: string) => void; // Додаємо функцію для відкриття ThankYou модального вікна
}

const FormComponent: FC<FormComponentProps> = ({ closeModal, openThankYouModal }) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState<Omit<ILetterModel, 'id' | 'likes' | 'likedBy'>>({
        age: '',
        senderName: '',
        senderRegion: '',
        letterText: '',
        tags: [],
        dataProcessingConsent: false,
        photos: [],
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const [errors, setErrors] = useState<Record<string, string>>({});

    const modalContentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (modalContentRef.current) {
            setTimeout(() => {
                modalContentRef.current?.scrollTo(0, 0);
            }, 50);
        }
    }, []);

    const validateForm = () => {
        const { error } = letterValidationSchema.validate(formData, { abortEarly: false });
        if (!error) return null;

        const validationErrors: Record<string, string> = {};
        error.details.forEach((err) => {
            validationErrors[err.path[0]] = err.message;
        });

        return validationErrors;
    };

    const autoResize = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const textarea = e.target;
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value, type } = e.target;
        const newValue = type === 'checkbox' ? (e.target as HTMLInputElement).checked : value;

        setFormData({
            ...formData,
            [name]: newValue
        });
    };

    const handleTagChange = (tagItem: string) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            tags: prevFormData.tags.includes(tagItem)
                ? prevFormData.tags.filter(tag => tag !== tagItem)
                : [...prevFormData.tags, tagItem]
        }));
    };

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const files = Array.from(e.target.files);
            const currentFilesCount = uploadedFiles.length;

            if (currentFilesCount + files.length > 2) {
                setMessage('Ви можете завантажити не більше 2 фото.');
                return;
            }

            const newPhotoURLs: string[] = [];
            const newUploadedFiles: File[] = [];

            for (let file of files) {
                const storageRef = ref(storage, `letters/${file.name}`);
                const snapshot = await uploadBytes(storageRef, file);
                const downloadURL = await getDownloadURL(snapshot.ref);
                newPhotoURLs.push(downloadURL);
                newUploadedFiles.push(file);
            }

            setFormData((prevFormData) => ({
                ...prevFormData,
                photos: [...(prevFormData.photos || []), ...newPhotoURLs]
            }));

            setUploadedFiles((prevFiles) => [...prevFiles, ...newUploadedFiles]);
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        setMessage('');

        if (containsLink(formData.letterText)) {
            setMessage('Лист містить посилання. Будь ласка, видаліть його.');
            setLoading(false);
            return;
        }

        if (filterText(formData.letterText)) {
            setMessage('Лист містить нецензурну лексику. Будь ласка, виправте його.');
            setLoading(false);
            return;
        }

        const validationErrors = validateForm();
        if (validationErrors) {
            setErrors(validationErrors);
            setLoading(false);
            return;
        }

        try {
            const formDataWithTimestamp = {
                ...formData,
                createdAt: serverTimestamp(),
                likes: 0,
                likedBy: []
            };

            const docRef: DocumentReference = await addDoc(collection(db, "letters"), formDataWithTimestamp);
            const newLetterId = docRef.id; // Отримуємо id нового листа

            setFormData({
                age: '',
                senderName: '',
                senderRegion: '',
                letterText: '',
                tags: [],
                dataProcessingConsent: false,
                photos: []
            });

            openThankYouModal(newLetterId);

        } catch (error) {
            setMessage(`Помилка при надсиланні листа: ${(error as Error).message}`);
        } finally {
            setLoading(false);
        }
    };

    const handleWrapperClick = () => {
        const fileInput = document.getElementById("fileInput") as HTMLInputElement;
        if (fileInput) {
            fileInput.click();
        }
    };

    return (
        <div ref={modalContentRef}>
            <form className={style.form} onSubmit={handleSubmit}>
                <div className={style.blockWrapper}>
                    <div className={style.userInfo}>
                        <h2 className={style.h2Letter}>{t("address")} <br/>{t("for_free_radio")}</h2>
                        <div className={style.userGrid}>
                            <div className={style.inputBlock}>
                                <label>{t('sender_name')}</label>
                                <input type="text" name="senderName" value={formData.senderName} onChange={handleChange} required />
                            </div>
                            <div className={style.inputBlock}>
                                <label>{t('age_optional')}</label>
                                <input
                                    type="number"
                                    name="age"
                                    value={formData.age}
                                    onChange={handleChange}
                                    min="1"
                                    max="120"
                                    required={false}
                                />
                            </div>
                            <div className={style.inputBlock}>
                                <label>{t('current_address')}</label>
                                <select
                                    name="senderRegion"
                                    value={formData.senderRegion}
                                    onChange={handleChange}
                                    className={formData.senderRegion ? style.selected : style.notSelected}
                                    required
                                >
                                    <option value="" disabled hidden>{t('select_region')}</option>
                                    {REGIONS.map(region => (
                                        <option key={region} value={region}>
                                            {t(`regions.${region}`)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className={style.imgWrapper} onClick={handleWrapperClick}>
                        <label className={style.customFileInput}>
                            {t('attach_photo')}
                        </label>
                        <input
                            id="fileInput"
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={handleFileChange}
                            disabled={uploadedFiles.length >= 2}
                            style={{display: 'none'}}
                        />
                        <p>{uploadedFiles.length}/2</p>
                    </div>
                </div>

                <div className={style.textWrapper}>
                    <label hidden>Текст листа:</label>
                    <textarea className={style.underlineParagraph} name="letterText" value={formData.letterText} onChange={handleChange} required onInput={autoResize} />
                </div>

                <div className={style.tagWrapper}>
                    <label>{t('select_tags_optional')}</label>
                    <div className={style.tagButtons}>
                        {TAGS.map(tagItem => (
                            <button
                                key={tagItem}
                                type="button"
                                className={formData.tags.includes(tagItem) ? style.activeTag : style.inactiveTag}
                                onClick={() => handleTagChange(tagItem)}
                            >
                                {t(`tags.${tagItem}`)}
                            </button>
                        ))}
                    </div>
                </div>

                <div className={style.checkBlock}>
                    <label className={style.checkboxLabel}>{t('agree_data_processing')}</label>
                    <input
                        type="checkbox"
                        name="dataProcessingConsent"
                        checked={formData.dataProcessingConsent}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className={style.validButton}>
                    {message && <p className={style.errorMessage}>{message}</p>}
                    {Object.keys(errors).map((key) => (
                        <div key={key} style={{ color: 'red' }}>
                            {errors[key]}
                        </div>
                    ))}
                    <button type="submit" disabled={loading}>
                        {loading ? 'Надсилання...' : `${t('send_letter')}`}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default FormComponent;
