export const keyMapping: { [key: string]: string } = {
    dnipro: 'dnipropetrovska',
    zaporizhzhia: 'zaporizka',
    kyiv_region: 'kyivska',
    chernihiv: 'chernihivska',
    odesa_region: 'odeska',
    donetsk_region: 'donetska',
    kharkiv_region: 'kharkivska',
    cherkasy: 'cherkaska',
    rivne: 'rivnenska',
    ternopil: 'ternopilska',
    khmelnytskyi: 'khmelnytska',
    kalush: 'ivano_frankivska',
    poltava_region: 'poltavska',
    lviv: 'lvivska',
    zakarpatia: 'zakarpatska',
    chernivtsi: 'chernivetska',
    volunska: 'volynska',
    zhytomyr: 'zhytomyrska',
    vinnytsia: 'vinnytska',
    mykolaiv: 'mykolaivska',
    krym: 'avtonomna_respublika_krym',
    herson: 'khersonska',
    luhantsk: 'luhanska',
    kirovograd: 'kirovohradska',
    sumy: 'sumska'
};