import React, {FC} from 'react';
import style from './ThankYouComponent.module.css';
import DonatesInfoComponent from "../DonatesInfoComponent/DonatesInfoComponent";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";

interface ThankYouComponentProps {
    letterId: string; // Пропс для id листа
}

const ThankYouComponent: FC<ThankYouComponentProps> = ({letterId}) => {
    const {t} = useTranslation();



    return (
        <div className={style.thankContainer}>
            <div className={style.thankBlock}>
                <h2>{t("thankYou")}</h2>
                <p>{t("letterLink")}</p>
                <NavLink to={`https://letters-to-bakhmut.freeradio.com.ua/letters/${letterId}`}>
                    {`https://letters-to-bakhmut.freeradio.com.ua/letters/${letterId}`}
                </NavLink>
            </div>
            <div className={style.donatComponent}>
                <DonatesInfoComponent/>
            </div>
        </div>
    );
};

export default ThankYouComponent;