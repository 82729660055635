import React from 'react';
import style from './TagListComponent.module.css';
import { TAGS } from "../../constants/tags";
import { useTranslation } from 'react-i18next';

interface TagListComponentProps {
    selectedTags: string[];
    handleTagClick: (tag: string) => void;
    letterTags?: string[];
}

const TagListComponent: React.FC<TagListComponentProps> = ({ selectedTags, handleTagClick, letterTags }) => {
    const { t } = useTranslation();
    const tagsToDisplay = letterTags && letterTags.length > 0 ? letterTags : TAGS;

    return (
        <div className={style.tagList}>
            {tagsToDisplay.map((tag) => (
                <div
                    key={tag}
                    className={`${style.tagBadge} ${selectedTags.includes(tag) ? style.selected : ''}`}
                    onClick={() => handleTagClick(tag)}
                >
                    {t(`tags.${tag}`)}
                </div>
            ))}
        </div>
    );
};

export default TagListComponent;
