import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        supportedLngs: ['en', 'ua', 'ru'],  // Мови, які підтримуються
        fallbackLng: 'ua',  // Мова за замовчуванням — українська
        lng: 'ua',          // Встановлюємо українську мову на старті
        detection: {
            order: ['queryString', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
            caches: ['cookie'],
        },
        backend: {
            loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/translation.json`,   // Шлях до файлів перекладу
        },
        react: {
            useSuspense: false,  // Відключаємо режим очікування
        },
    });

export default i18n;
