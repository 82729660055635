import React, { FC } from 'react';
import LikeButton from '../LikeButtonComponent/LikeButtonComponent';
import { ILetterModel } from "../../models/ILetterModel";
import style from './DemoLetterCard.module.css';
import { useTranslation } from "react-i18next";

interface DemoLetterCardProps {
    letter: ILetterModel;
    onClick: () => void; // Ensure onClick is required
}

const DemoLetterCard: FC<DemoLetterCardProps> = ({ letter, onClick }) => {
    const { t } = useTranslation();

    const handleLikeClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation(); // Prevent modal from opening when like button is clicked
    };

    return (
        <div className={style.card} onClick={onClick}>
            <div>
                <p className={style.text}>{letter.letterText.substring(0, 60)}...</p>
            </div>
            <div className={style.infoContainer}>
                <div className={style.blockWrapper}>
                    <div className={style.paragraphWrapper}>
                        <p>{t('sender')}</p>
                        <h3 className={style.nameBlock}>
                            {letter.senderName}
                            {letter.age && `, ${letter.age} ${t('years')}`}
                        </h3>
                    </div>
                    <div>
                        <h3 className={style.nameBlock}>{t(`regions.${letter.senderRegion}`)}</h3>
                    </div>
                </div>
                <div className={style.photoWrapper}>
                    {letter.photos && letter.photos[0] && (
                        <img src={letter.photos[0]} alt="LetterPhoto" className={style.photo} />
                    )}
                </div>
            </div>
            <div className={style.likeButton} onClick={handleLikeClick}>
                <LikeButton singleLetterId={letter.id} initialLikes={letter.likes} />
            </div>
        </div>
    );
};

export default DemoLetterCard;


