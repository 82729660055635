import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {fetchTotalAmount} from "../../../services/firestoreService";
import style from './DonatesComponent.module.css'
import DonatesInfoComponent from "../../DonatesInfoComponent/DonatesInfoComponent";

const DonatesComponent = () => {
    const {t} = useTranslation();
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const maxAmount = 453000;
    useEffect(() => {
        const getTotalAmount = async () => {
            const amount = await fetchTotalAmount();
            setTotalAmount(amount ?? 0);
        };

        getTotalAmount();
    }, []);
    const innerWidth = (totalAmount / maxAmount) * 100;
    return (
        <section className={style.donateSection}>
            <div >
                <p className={style.donGoal}>{t('goal')}:</p>
                <div className={style.sumBlock}>
                    <div className={style.svgBlockOne}>
                        <div className={style.svgContainer}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="67" height="129" viewBox="0 0 67 129"
                                 fill="none">
                                <circle cx="14.5622" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="14.5622" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="14.5622" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="14.5622" cy="14.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="14.5622" cy="76.6872" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="39.5622" cy="39.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="39.5622" cy="101.686" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="52.0619" cy="51.6881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="52.0622" cy="114.186" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="27.0624" cy="26.6862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="27.0624" cy="89.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="27.0624" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="27.0622" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="27.0624" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="39.5622" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="39.5622" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="39.5622" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="52.0619" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="52.0622" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="52.0619" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="51.6881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="51.6881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5622" cy="114.186" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="114.186" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5622" cy="39.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="39.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="101.686" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="101.686" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="26.6862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="26.6862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="89.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="89.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="14.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="14.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5622" cy="76.6872" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="76.6872" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5622" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                            </svg>

                            <svg xmlns="http://www.w3.org/2000/svg" width="67" height="130" viewBox="0 0 67 130"
                                 fill="none"
                                 className="overlay-svg">
                                <path d="M1.99976 2.25001V65.2504H64.6251V0.75V127.876" stroke="black"
                                      strokeWidth="3.75"
                                      strokeLinecap="square"/>
                            </svg>
                        </div>
                        <div className={style.svgContainer}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="67" height="129" viewBox="0 0 67 129"
                                 fill="none">
                                <circle cx="14.5622" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="14.5622" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="14.5622" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="14.5622" cy="14.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="14.5622" cy="76.6872" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="39.5622" cy="39.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="39.5622" cy="101.686" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="52.0619" cy="51.6881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="52.0622" cy="114.186" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="27.0624" cy="26.6862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="27.0624" cy="89.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="27.0624" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="27.0622" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="27.0624" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="39.5622" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="39.5622" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="39.5622" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="52.0619" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="52.0622" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="52.0619" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="51.6881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="51.6881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5622" cy="114.186" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="114.186" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5622" cy="39.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="39.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="101.686" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="101.686" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="26.6862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="26.6862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="89.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="89.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="14.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="14.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5622" cy="76.6872" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="76.6872" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5622" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                            </svg>

                            <svg xmlns="http://www.w3.org/2000/svg" width="67" height="130" viewBox="0 0 67 130"
                                 fill="none">
                                <path d="M64.3751 2.25H2.12476V65.2503H64.3751V127.876H2.12476" stroke="black"
                                      strokeWidth="3.75"
                                      strokeLinecap="square"/>
                            </svg>
                        </div>
                        <div className={style.svgContainer}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="67" height="129" viewBox="0 0 67 129"
                                 fill="none">
                                <circle cx="14.5622" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="14.5622" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="14.5622" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="14.5622" cy="14.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="14.5622" cy="76.6872" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="39.5622" cy="39.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="39.5622" cy="101.686" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="52.0619" cy="51.6881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="52.0622" cy="114.186" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="27.0624" cy="26.6862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="27.0624" cy="89.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="27.0624" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="27.0622" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="27.0624" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="39.5622" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="39.5622" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="39.5622" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="52.0619" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="52.0622" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="52.0619" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="51.6881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="51.6881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5622" cy="114.186" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="114.186" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5622" cy="39.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="39.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="101.686" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="101.686" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="26.6862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="26.6862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="89.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="89.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="14.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="14.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5622" cy="76.6872" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="76.6872" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5622" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                            </svg>

                            <svg xmlns="http://www.w3.org/2000/svg" width="67" height="131" viewBox="0 0 67 131"
                                 fill="none">
                                <path
                                    d="M2.24976 2.625H64.8751V65.4378M2.24976 128.251H64.8751V65.4378M64.8751 65.4378H2.24976"
                                    stroke="black" strokeWidth="3.75" strokeLinecap="square"/>
                            </svg>
                        </div>
                    </div>
                    <div className={style.svgBlockTwo}>
                        <div className={style.svgContainer}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="67" height="129" viewBox="0 0 67 129"
                                 fill="none">
                                <circle cx="14.5622" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="14.5622" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="14.5622" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="14.5622" cy="14.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="14.5622" cy="76.6872" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="39.5622" cy="39.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="39.5622" cy="101.686" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="52.0619" cy="51.6881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="52.0622" cy="114.186" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="27.0624" cy="26.6862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="27.0624" cy="89.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="27.0624" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="27.0622" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="27.0624" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="39.5622" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="39.5622" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="39.5622" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="52.0619" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="52.0622" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="52.0619" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="51.6881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="51.6881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5622" cy="114.186" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="114.186" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5622" cy="39.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="39.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="101.686" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="101.686" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="26.6862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="26.6862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="89.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="89.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="14.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="14.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5622" cy="76.6872" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="76.6872" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5622" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                            </svg>
                            <svg viewBox="0 0 88 171" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect x="2.5" y="2.5" width="83" height="166" stroke="black" strokeWidth="5"/>
                            </svg>
                        </div>
                        <div className={style.svgContainer}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="67" height="129" viewBox="0 0 67 129"
                                 fill="none">
                                <circle cx="14.5622" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="14.5622" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="14.5622" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="14.5622" cy="14.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="14.5622" cy="76.6872" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="39.5622" cy="39.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="39.5622" cy="101.686" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="52.0619" cy="51.6881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="52.0622" cy="114.186" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="27.0624" cy="26.6862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="27.0624" cy="89.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="27.0624" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="27.0622" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="27.0624" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="39.5622" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="39.5622" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="39.5622" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="52.0619" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="52.0622" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="52.0619" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="51.6881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="51.6881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5622" cy="114.186" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="114.186" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5622" cy="39.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="39.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="101.686" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="101.686" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="26.6862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="26.6862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="89.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="89.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="14.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="14.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5622" cy="76.6872" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="76.6872" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5622" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                            </svg>
                            <svg viewBox="0 0 88 171" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect x="2.5" y="2.5" width="83" height="166" stroke="black" strokeWidth="5"/>
                            </svg>
                        </div>
                        <div className={style.svgContainer}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="67" height="129" viewBox="0 0 67 129"
                                 fill="none">
                                <circle cx="14.5622" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="14.5622" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="14.5622" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="14.5622" cy="14.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="14.5622" cy="76.6872" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="39.5622" cy="39.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="39.5622" cy="101.686" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="52.0619" cy="51.6881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="52.0622" cy="114.186" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="27.0624" cy="26.6862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="27.0624" cy="89.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="27.0624" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="27.0622" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="27.0624" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="39.5622" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="39.5622" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="39.5622" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="52.0619" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="52.0622" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="52.0619" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="126.688" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="51.6881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="51.6881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5622" cy="114.186" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="114.186" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5622" cy="39.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="39.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="101.686" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="101.686" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="26.6862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="26.6862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="89.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="89.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="14.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="14.1881" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5622" cy="76.6872" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="76.6872" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5622" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="1.68717" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="64.5624" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                                <circle cx="2.06217" cy="64.1862" r="1.56217" fill="black" fillOpacity="0.3"/>
                            </svg>
                            <svg viewBox="0 0 88 171" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect x="2.5" y="2.5" width="83" height="166" stroke="black" strokeWidth="5"/>
                            </svg>
                        </div>
                        <p className={style.hrn}>{t('uah')}</p>
                    </div>
                </div>
                <div className={style.recWrap}>
                    <div className={style.outerRectangle}>
                        <div
                            className={style.innerRectangle}
                            style={{width: `${innerWidth}%`}}
                        ></div>
                    </div>
                </div>
                <p className={style.collectedAmount}>{t('collected')}: <span>{totalAmount !== null ? `${totalAmount}` : 'Завантаження...'}</span>
                    <span>{t('uah')}</span>
                </p>
                <div className={style.donateInfo}>
                <DonatesInfoComponent/>
                </div>
            </div>


        </section>
    );
};

export default DonatesComponent;