import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import style from './LanguageSwitcherComponent.module.css';

const LanguageSwitcherComponent = () => {
    const { i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

    const changeLanguage = (lang: string) => {
        i18n.changeLanguage(lang);
        setSelectedLanguage(lang);
        setIsOpen(false);
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const availableLanguages = ['ua', 'en', 'ru'].filter(lang => lang !== selectedLanguage);

    return (
        <div className={style.selectContainer}>
            <div className={style.selectedOption} onClick={toggleDropdown}>
                {selectedLanguage.toUpperCase()}
                {isOpen ? (
                    <svg className={style.svg} xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                        <path d="M1 1L5 5L9 1" stroke="black" strokeLinecap="round" />
                    </svg>
                ) : (
                    <svg className={style.svg} xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                        <path d="M1 9L5 5L1 1" stroke="black" strokeLinecap="round" />
                    </svg>
                )}
            </div>

            {isOpen && (
                <ul className={style.dropdown}>
                    {availableLanguages.map((lang) => (
                        <li key={lang} className={style.option} onClick={() => changeLanguage(lang)}>
                            {lang.toUpperCase()}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default LanguageSwitcherComponent;