import React from 'react';
import style from './DonatesInfoComponent.module.css'
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
const DonatesInfoComponent = () => {
    const {t} = useTranslation();
    return (
        <div className={style.donatesInfoContainer}>
            <div className={style.donateHeader} id="donates-section">
                <h2> {t('fundraising_goal')} <br/> <span>{t('dronefall_project')}</span></h2>
            </div>

            <div className={style.donateTextContainer}>
                <p>{t('support_armed_forces')}</p>
                <div className={style.donText}>
                    <p>{t('all_funds_to_bank')}</p>
                    <NavLink to="https://send.monobank.ua/jar/7Zq31SyvUC" target="_blank" rel="noopener noreferrer">
                       {t('make_donate')}
                    </NavLink>
                </div>
            </div>

        </div>
    );
};

export default DonatesInfoComponent;